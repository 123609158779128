import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export function initSlider() {
  if (document.querySelector('.js-mv-slider')) {
    const mvSwiper = new Swiper('.js-mv-slider', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      speed: 2000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
    });
  }

  if (document.querySelector('.js-interview-slider')) {
    const swiperWrapper = document.querySelector(".swiper-wrapper");
    const lastSwiperSlide = document.querySelector(".swiper-slide:last-of-type");
    const interviewSwiper = new Swiper('.js-interview-slider', {
      loop: true,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesOffsetAfter: swiperWrapper.clientWidth - lastSwiperSlide.clientWidth,
    });

    interviewSwiper.snapGrid[interviewSwiper.snapGrid.length - 1] = interviewSwiper.slidesGrid[interviewSwiper.slidesGrid.length - 1];
  }
}


