import Vue from 'vue';
import * as animation from './functions/_animation';
import * as variables from './functions/_variables';
import * as layout from './functions/_layout';
import * as slider from './functions/_slider';
import * as ie_alert from './functions/_ie_alert';


new Vue({
  el: '#app',
  data() {
    return {
      windowSizeSp: false
    }
  },
  mounted() {
    this.handleResize()
    window.addEventListener('resize', () => {
      this.handleResize
      layout.smoothScroll();
    }, false)
    animation.scrollObserver();
    // layout.headerHeightMarginTop();
    layout.HeaderColorChange();
    layout.HeaderNavToggle();
    layout.footerAccMenu();
    layout.pageTop();
    layout.faqAccordion();
    layout.smoothScroll();
    layout.formCookieDelete();
    slider.initSlider();
    ie_alert.init_ie_alert();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize, false)
  },
  methods: {
    /**
     * window width sp
     * @return boolean
     */
    handleResize() {
      if (window.innerWidth < 769) {
        this.windowSizeSp = true;
      } else {
        this.windowSizeSp = false;
      }
    }
  },
});