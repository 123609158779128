import * as variables from "./_variables";

export function init_ie_alert(){

  function isIE() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    if ( userAgent.indexOf( 'msie' ) !== -1 || userAgent.indexOf( 'trident' ) !== -1 ) {
      return true;
    }
    return false;
  }
  
  document.addEventListener("DOMContentLoaded", function () {
    if ( isIE() ) {
      elm.classList.add("is-active");
      var elm = document.getElementById('ie_alert');
    }
  });
}