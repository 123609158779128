import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import * as variables from "./_variables";

/*
 header height margin
 */
export function headerHeightMarginTop() {
  if(!document.querySelector('.home')){
    function addMarginTop() {
      let headerHeight = variables.header.clientHeight ;
      variables.body.style.marginTop = headerHeight +'px';

      console.log(variables.body.style.marginTop = headerHeight +'px')
    }

    window.addEventListener('resize', addMarginTop, false)
    window.addEventListener('load', addMarginTop, false)
  }
}


/*
 header scroll color change
 */
export function HeaderColorChange() {
  if (document.querySelector('.js-secMv')) {
    window.addEventListener("scroll", function () {
      let mvHeight = document.querySelector('.js-secMv').clientHeight;
      variables.body.classList.toggle("is-changeHeader", window.scrollY > mvHeight);
    });
  } else if (document.querySelector('.js-sec-kv')) {
    window.addEventListener("scroll", function () {
      let kvHeight = document.querySelector('.js-sec-kv').clientHeight;
      variables.body.classList.toggle("is-changeHeader", window.scrollY > 10);
    });
  } else {
    variables.body.classList.add("is-changeHeader");
  }
}

/*
 header toggle nav
 */
export const HeaderNavToggle = () => {
  const target = document.querySelector('.js-drawerToggle');
  const targetText = document.querySelector('.js-drawerToggle p');
  let flag = false;

  target.addEventListener('click', () => {
    flag = !flag;
    body.classList.toggle('is-viewNav');
    if (flag === false) {
      targetText.textContent = 'MENU'
      clearAllBodyScrollLocks();
    } else {
      targetText.textContent = 'CLOSE'
      disableBodyScroll(hnav);
    }
  })
}

/*
 footer accordion
 */

export const footerAccMenu = () => {
  const accTarget = document.querySelectorAll('.js-accTarget');

  accTarget.forEach((elm) => {
    elm.addEventListener('click', () => {
      elm.classList.toggle('is-open');
      elm.nextElementSibling.classList.toggle('is-open');
    })
  });
}


/*
 page top
 */

export const pageTop = () => {
  var pageTop = document.querySelector('.page-top')

  pageTop.addEventListener('click', () => {
    window.scroll({top: 0, behavior: 'smooth'});
  });
}

/*
 faq accordion
 */

export const faqAccordion = () => {
  if(document.querySelector('.js-faqAccTarget')) {
    const accTarget = document.querySelectorAll('.js-faqAccTarget');

    accTarget.forEach((elm) => {
      elm.addEventListener('click', () => {
        elm.classList.toggle('is-open');
        elm.nextElementSibling.classList.toggle('is-open');
      })
    });
  }
}

/*
 smoothScroll
 */

export const smoothScroll = () => {
  const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
  let headerHeight = variables.header.clientHeight ;
  for (let i = 0; i < smoothScrollTrigger.length; i++) {
    smoothScrollTrigger[i].addEventListener('click', (e) => {
      e.preventDefault();
      let href = smoothScrollTrigger[i].getAttribute('href');
      let targetElement = document.getElementById(href.replace('#', ''));
      const rect = targetElement.getBoundingClientRect().top;
      const offset = window.pageYOffset;
      const gap = headerHeight;
      const target = rect + offset - gap;
      window.scrollTo({
        top: target,
        behavior: 'smooth',
      });
    });
  }
}

/**
 * エントリーフォーム入力情報を使用ページ以外はリセット
 */
export const formCookieDelete = () => {
  if (!(document.querySelector('.single-joblist') || document.querySelector('.page-id-446'))) {
    document.cookie = "cf7msm_posted_data=; expires=; path=/";
  }
}